@use "src/styles/theme" as *; $themeName: "fixtercore";

@use '$styles/values';
@use '$styles/mediaqueries';
@use "$styles/variables/breakpoints";

.component {
    background: values.$colorNeutral100;
    padding: values.$space8 0;
}

.title {
    color: values.$colorRed500;
    margin-bottom: values.$space6 !important;
    text-align: center;
    @include mediaqueries.mediumUp {
      text-align: left;
    }
}

.allServicesLink {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75%;
}

.disclaimerSection {
    text-align: center;
    @include mediaqueries.mediumUp {
        max-width: 75%;
        margin: values.$space4 auto 0;
    }

    .disclaimer,
    .learnMore {
        font: values.$fontLabelXS;
        color: values.$colorNeutral600;
        font-weight: 500;
    }

    .learnMore {
        text-decoration: underline;
        cursor: pointer;
        border: none;
        padding: 0;
    }
}

.modal {
    padding: 0 values.$space5;
    @include mediaqueries.mediumUp {
        max-width: 450px;
        padding: 0;
    }
    &__title {
        margin-bottom: values.$space4 !important;
    }

    &__service {
        display: flex;
        flex-direction: column;
        &:not(:last-child) {
            margin-bottom: values.$space8;
        }
    }

    &__serviceName {
        color: values.$colorRed500;
    }

    &__servicePrice {
        color: values.$colorNeutral800;
        margin-bottom: values.$space2;
    }

    &__serviceName,
    &__servicePrice {
        font-size: values.$px_16;
        font-weight: 700;
        line-height: values.$px_20;
    }

    &__termsConditions p {
        font-size: values.$px_14;
        font-weight: 500;
        line-height: values.$px_20;
        margin: 0;
    }
}